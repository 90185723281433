import Layout from "./common/layout"
import Head from "./common/Head"
import Media from "./company/Media/Media"
import { useFormatter } from "helpers/i18n"

const CompanyMedia = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "News coverage related to OnPoint"
            : "Tin tức liên quan đến OnPoint"
        }
        description={
          currentLocale === "en"
            ? "Media coverage of OnPoint, Vietnam's number one e-commerce enabler"
            : "Tin tức về OnPoint, nhà cung cấp các dịch vụ hỗ trợ thương mại điện tử số 1 Việt Nam"
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/company-3-meta.png"
        }
      />
      <Layout>
        <Media />
      </Layout>
    </>
  )
}

export default CompanyMedia
